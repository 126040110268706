<template>
  <div class="SourceList">
    <Breadcrumbs class="main-top">
      <template slot="action-buttons">
        <v-btn depressed color="primary" to="/method/create">
          {{ $t("source.Create Authentication Method") }}
        </v-btn>
      </template>
    </Breadcrumbs>
    <v-alert
      outlined
      dense
      text
      type="info"
      icon="mdi-lightbulb-on-outline"
      class="text-left rounded-sm body-2 pa-4 content-wrapper"
    >
      <span>认证方式</span><br />
      外部认证方式即允许使用第三方认证方式登录账户，可根据公司需要添加并使用不同的认证方式，如微信扫码、微信公众号、短信验证码等。<br />
      与认证方式不同，认证方式是为现有账户提供了一种登录方式，不能创建新用户。
    </v-alert>
    <v-simple-table class="table-wrapper">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left pl-4">
              ID
            </th>
            <th class="text-left">
              {{ $t("Authentication Name") }}
            </th>
            <th class="text-left">
              {{ $t("Authentication Adapter") }}
            </th>
            <th class="text-left">
              {{ $t("Enabled") }}
            </th>
            <th class="text-center">
              {{ $t("Actions") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="methods.length > 0">
          <tr v-for="item in methods" :key="item.id" class="text-left">
            <td class="pl-4">{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.type }}</td>
            <td>
              <v-switch
                inset
                hide-details="true"
                dense
                v-model="item.enabled"
                :loading="item.loading"
                :disabled="item.builtin"
                @click.stop="change(item)"
              ></v-switch>
            </td>
            <td class="text-center">
              <v-btn
                v-if="item.type !== 'PASSWORD'"
                icon
                :disabled="item.builtin || item.type === 'WECHAT_RZKC'"
                @click.stop="edit(item)"
              >
                <v-icon>
                  mdi-tune-vertical
                </v-icon>
              </v-btn>
              <v-btn v-else icon @click.stop="edit(item)">
                <v-icon>
                  mdi-tune-vertical
                </v-icon>
              </v-btn>
              <v-btn
                v-if="item.type !== 'PASSWORD'"
                icon
                :disabled="item.builtin || item.type === 'WECHAT_RZKC'"
                elevation="0"
                @click.stop="show_info(item)"
              >
                <v-icon> mdi-text-box-search-outline </v-icon>
              </v-btn>
              <v-btn v-else icon elevation="0" @click.stop="show_info(item)">
                <v-icon> mdi-text-box-search-outline </v-icon>
              </v-btn>
              <v-btn icon elevation="0" @click.stop="show_log(item)">
                <v-icon> mdi-math-log </v-icon>
              </v-btn>
              <v-btn
                icon
                elevation="0"
                color="red"
                :disabled="item.enabled"
                @click.stop="deleteSource(item)"
              >
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td
              colspan="5"
              style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
            >
              暂无数据
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-pagination
      v-if="Math.ceil(totalPage / limit) > 1"
      v-model="curPage"
      :length="Math.ceil(totalPage / limit)"
      total-visible="7"
      @input="onPageChange(curPage, limit)"
    ></v-pagination>
    <Component
      :is="sheet_editor"
      type="edit"
      :is_readonly="readonly"
      :source_id="source_id"
      :allDatas="allDatas"
      :psdSources="psdSources"
    />
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import Breadcrumbs from "@/components/Breadcrumbs";
import ConfirmBox from "@/components/ConfirmBox";
import SmsEditor from "@/views/method/editor/Sms";
import PasswordEditor from "@/views/method/editor/Password";
import WeiBoEditor from "@/views/method/editor/WeiBo";
import WechatEditor from "@/views/method/editor/Wechat";
import NactaEditor from "@/views/method/editor/Nacta";
import WechatOfficialEditor from "@/views/method/editor/WechatOfficial";
import WechatWebSiteEditor from "@/views/method/editor/WechatWebSite";

import bus from "@/util/eventBus";

const EDITORS = {
  SMS: SmsEditor,
  PASSWORD: PasswordEditor,
  WEIBO: WeiBoEditor,
  WECHAT: WechatEditor,
  NACTA_AUTH: NactaEditor,
  WECHAT_OFFICIAL: WechatOfficialEditor,
  WECHAT_WEBSITE: WechatWebSiteEditor
};

export default {
  name: "MethodList",
  data() {
    return {
      curPage: 1,
      limit: 10,
      totalPage: 0,
      sheet_editor: null,
      current_id: null,
      show_confirm: null,
      readonly: null,
      methods: [],
      sources: [],
      psdSources: [],
      allDatas: {},
      type: null,
      source_id: "",
      curId: null
    };
  },
  created() {
    this.refreshList();
    bus.$off("refresh").$on("refresh", () => {
      this.onPageChange(this.curPage, 10);
    });
  },
  methods: {
    async edit(item) {
      if (item.id === 13 || item.id === 6) {
        return this.$snackbar.showMessage({
          content: "该认证方式无可配置项",
          isError: true
        });
      } else {
        if (item.type === "PASSWORD") {
          await this.fetchSources();
          let psdMethods = this.methods.filter(
            item => item.enabled && item.type === "PASSWORD"
          );
          this.psdSources = this.sources.concat(psdMethods);
        }
        this.curId = item.id;
        this.fetchData(item.id);
        this.source_id = item.id;
        this.sheet_editor = EDITORS[item.type];
        this.readonly = null;
        setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
      }
    },
    onPageChange(curPage, limit) {
      this.refreshList(curPage, limit);
    },
    async show_info(item) {
      if (item.id === 13) {
        return this.$snackbar.showMessage({
          content: "该认证方式无可配置项",
          isError: true
        });
      } else {
        if (item.type === "PASSWORD") {
          await this.fetchSources();
          let psdMethods = this.methods.filter(
            item => item.enabled && item.type === "PASSWORD"
          );
          this.psdSources = this.sources.concat(psdMethods);
        }
        this.curId = item.id;
        this.fetchData(item.id);
        this.source_id = item.id;
        this.sheet_editor = EDITORS[item.type];
        this.readonly = true;
        setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
      }
    },
    show_log(item) {
      this.$router.push({
        path: "/audit/login",
        query: {
          id: item.id
        }
      });
    },
    deleteSource({ id, name }) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除认证方式？ #${id} ${name}`,
          callback: () => {
            return this.$http
              .post(`api/source/${id}/archive`)
              .delegateTo(api_request)
              .then(() => {
                this.curPage =
                  this.curPage > Math.ceil((this.totalPage - 1) / 10)
                    ? this.curPage - 1
                    : this.curPage;
                if (this.totalPage - 1 > 0) {
                  this.onPageChange(this.curPage, 10);
                } else {
                  this.refreshList();
                }
                return "认证方式已成功删除";
              })
              .catch(({ code, message }) => {
                throw `认证方式删除失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    change(item) {
      const index = this.methods.findIndex(_item => {
        return item.id === _item.id;
      });

      item.loading = true;
      const operationText = item.enabled ? "开启" : "关闭";
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要${operationText}认证方式 ${item.name}？`,
          callback: function() {
            return this.$http
              .post(`api/source/${item.id}/enable`, {
                enable: item.enabled
              })
              .delegateTo(api_request)
              .then(() => {
                return `认证方式 ${item.name} 已成功${operationText}`;
              })
              .catch(({ code, message }) => {
                throw `认证方式 ${item.name} ${operationText}失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          item.enabled = !item.enabled;
        })
        .finally(() => {
          this.onPageChange(this.curPage, 10);
          item.loading = false;
          this.$set(this.methods, index, this.methods[index]);
        });
    },
    refreshList(curPage = 1, limit = 10) {
      return this.$http
        .get(`api/method/list?offset=${(curPage - 1) * limit}&limit=${limit}`)
        .delegateTo(api_request)
        .then(res => {
          this.totalPage = res.total;
          this.methods = res.sources;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchData(id) {
      this.$http
        .get(`api/source/${id}/describe`)
        .delegateTo(api_request)
        .then(data => {
          this.type = data.type;
          this.allDatas = data;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchSources() {
      return this.$http
        .get(`api/source/list`)
        .delegateTo(api_request)
        .then(res => {
          this.sources = res.sources.filter(
            item =>
              item.enabled &&
              (item.type === "LDAP" ||
                item.type === "CAS" ||
                item.type === "CAS_REST_API" ||
                item.type === "WEBVPN")
          );
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    }
  },
  components: {
    Breadcrumbs,
    ConfirmBox,
    SmsEditor,
    WeiBoEditor,
    WechatEditor,
    NactaEditor,
    WechatOfficialEditor,
    WechatWebSiteEditor
  }
};
</script>

<style lang="less" scoped>
.SourceList {
  .table-wrapper {
    min-height: 600px;
  }
  /deep/.v-pagination {
    justify-content: flex-end;
    margin-top: 20px;
    width: 96%;
  }
  .v-alert {
    /deep/ .v-alert__content {
      color: #213142;
    }
    /deep/ .v-alert__icon {
      font-size: 2rem !important;
      height: 3rem;
    }
  }
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
